<template>
  <div class="mobileCurriculum">
    <van-nav-bar
      :title="viewTitle"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="loading" v-if="this.erroring">
      <div>{{ tips }}</div>
    </div>
    <div class="content" v-if="!this.erroring">
      <div class="title">
        {{ itemData && itemData.title }}
      </div>
      <el-card class="box-card" v-if="examination && !adjustment">
        <div class="card">
          <div>
            【{{
              examination.subject.type == "SC"
                ? "单选题"
                : examination.subject.type == "MC"
                ? "多选题"
                : examination.subject.type == "TF"
                ? "判断题"
                : "简答题"
            }}】
          </div>
          <p style="font-size: 16px">
            {{ examination.index + 1 }}. {{ examination.subject.title }}
          </p>
          <el-radio-group
            v-model="examination.value"
            v-if="examination.subject.type == 'SC'"
          >
            <el-radio
              v-for="(opt, _opt) in examination.subject.options"
              :key="_opt"
              :label="opt.index"
              >{{ opt.title }}</el-radio
            >
          </el-radio-group>
          <el-checkbox-group
            v-model="examination.value"
            v-if="examination.subject.type == 'MC'"
          >
            <el-checkbox
              v-for="(opt, _opt) in examination.subject.options"
              :key="_opt"
              :label="opt.index"
              >{{ opt.title }}</el-checkbox
            >
          </el-checkbox-group>
          <el-radio-group
            v-model="examination.value"
            v-if="examination.subject.type == 'TF'"
          >
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
          <el-input
            v-if="examination.subject.type == 'QA'"
            type="textarea"
            :rows="10"
            placeholder="请在该区域内作答"
            v-model="examination.value"
          >
          </el-input>
        </div>
        <div class="gradient-background next" @click="submit">
          {{ examination.total != examination.index + 1 ? "下一题" : "提交" }}
        </div>
      </el-card>
      <el-card class="tips-card">
        <p>剩余时间</p>
        <p class="timing">{{ timer }}</p>
        <el-divider></el-divider>
        <p>当前进度</p>
        <p>
          {{ examination && examination.index + 1 }}/{{
            examination && examination.total
          }}
        </p>
      </el-card>
    </div>
  </div>
</template>

<script>
import { domainName } from "../../assets/js/storage";
export default {
  name: "examination",
  data() {
    return {
      timer: "00:00",
      examination: null,
      itemData: null,
      erroring: false,
    };
  },
  created() {
    this.itemData = this.$route.query || null;
    this.$https
      .post("/api/my/exam/start/" + this.itemData.examinationId)
      .then((res) => {
        if (res.success) {
          this.erroring = false;
          const data = res.data;
          if (!data.subject) {
            this.tips = "考试数据异常！";
            return;
          }
          this.examination = {
            ...data,
            value: data.subject.type == "MC" ? [] : null,
          };
          this.totalTime = data.leftTime;
          this.setTimer();
        } else {
          this.erroring = true;
          this.tips = "错误：" + res.$message;
        }
      })
      .catch((error) => {
        this.erroring = true;
        this.tips = "错误：" + error.debug;
        return;
      });
  },
  methods: {
    setTimer() {
      const _this = this;
      let _timer = setInterval(function () {
        if (_this.totalTime) {
          _this.totalTime--;
          var minute = parseInt(_this.totalTime / 60);
          var second = parseInt(_this.totalTime % 60);
          _this.timer = minute + ":" + second;
        } else {
          clearInterval(_timer);
        }
      }, 1000);
    },
    submit() {
      if (this.examination.value == null) {
        this.$message.error("请先作答！");
        return;
      }
      this.$https
        .post("/api/my/exam/submitsubject/" + this.examination.id, {
          answer: this.examination.value,
          subjectIndex: this.examination.index,
        })
        .then((res) => {
          if (res.success) {
            const data = res.data;
            this.isDone = data && data.isDone;
            if (!data.isDone) {
              this.examination = {
                ...data,
                value: data.subject.type == "MC" ? [] : null,
              };
            } else {
              /*是否回显*/
              if (data.needDisplay) {
                /**判断是否极格 */
                let tipsTxt = "很遗憾，您没有通过此次考试";
                let tipsTxtCalss = "echoErrorTitle";
                if (data.pass) {
                  tipsTxt = "恭喜您通过此次考试";
                  tipsTxtCalss = "echoPassTitle";
                }
                const displayContentText = data.displayContent;
                const src = data.displayImage;
                const content =
                  '<div class="echoPass"><div class="' +
                  tipsTxtCalss +
                  '">' +
                  tipsTxt +
                  '</div><div class="echoPassContent">' +
                  displayContentText +
                  '</div><img style="max-width:100%;" src=' +
                  (this.$domainName + src) +
                  " /></div>";
                this.$confirm(content, "", {
                  confirmButtonText: "确定",
                  showCancelButton: false,
                  showClose: false,
                  dangerouslyUseHTMLString: true,
                  center: true,
                })
                  .then(() => {
                    this.onClickLeft();
                  })
                  .catch(() => {
                    this.onClickLeft();
                  });
              } else {
                this.$alert("", "考试完成！", {
                  confirmButtonText: "确定",
                  center: true,
                  callback: () => {
                    this.onClickLeft();
                  },
                });
              }
            }
          }
        });
    },
    onClickLeft() {
      this.$router.push({
        path: "/mobileHome",
      });
    },
  },
};
</script>

<style lang="less" scope>
.mobileCurriculum {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  .loading {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
  }
  .content {
    font-size: 18px;
    .title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      line-height: 50px;
    }
    .tips-card {
      position: absolute;
      right: 10px;
      top: 100px;
      background-color: #fff;
      text-align: center;
      line-height: 2;
      padding: 10px 30px;
      color: #5e6482;
      overflow: initial;
      font-size: 14px;
      .el-divider--horizontal {
        margin: 10px 0;
      }
      .timing {
        color: tomato;
      }
      .submit {
        position: absolute;
        bottom: -60px;
        width: 100%;
        left: 0;
      }
    }
    .box-card {
      position: relative;
      color: #5e6482;
      width: 60%;
      margin: 200px 20%;
      .el-card__header {
        font-size: 16px;
        font-weight: bold;
      }
      .card {
        font-size: 14px;
        padding: 20px;
        line-height: 30px;
        .el-radio,
        .el-checkbox {
          width: 100%;
          line-height: 40px;
          padding-left: 10px;
          font-size: 16px;
          margin-right: 0;
        }
        .el-radio__label {
          text-overflow: ellipsis;
          white-space: normal;
          line-height: 18px;
          vertical-align: middle;
          display: inline-block;
          width: 90%;
        }
        .el-checkbox__label {
          text-overflow: ellipsis;
          white-space: normal;
          line-height: 18px;
          vertical-align: middle;
          display: inline-block;
          width: 90%;
        }
      }
      .next {
        padding: 0 20px;
        float: right;
        margin: 0 20px 20px 0;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}
</style>
